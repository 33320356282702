import React from "react"
import styled from "styled-components"

import PostContent from "../../global/atoms/PostContent"
import Avatar from "../../global/atoms/Avatar"
import PostMeta from "../../global/atoms/PostMeta"
import Categories from "../../global/atoms/Categories"

import { Overlay } from "../../global/common/ornaments"
import { isLinkAnAnchor, ornament } from "../../../assets/styles/helpers/common"
import { ImageLink } from "../../global/common/links"

import SiteLanguage from "../../../helpers/siteLanguage"
import optimizedPath from "../../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../../helpers/getRangedSrcSet"

const PostImageLink = styled(ImageLink)`
  --overlay-color: var(--main-green-transparent-med);

  &::after {
    ${ornament}

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.45) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
`

const Author = styled.footer`
  display: flex;
  align-items: center;

  position: absolute;
  bottom: 0;

  color: #fff;
`

const CustomAvatar = styled(Avatar)`
  --avatar-size: 3rem;

  margin-right: 1rem;
`

const ListedPost = ({ data }) => {
  const {
    uri,
    slug,
    title,
    acfPost: { leadShort: excerpt, author: authorBase },
    categories: { nodes: categories },
  } = data

  const url = SiteLanguage() === "pl" ? `https://bravenew.agency/pl/wpisy/${slug}/` : `https://bravenew.agency/blog/${slug}/`

  const image = {
    url: getImageBySrcSetAndSize(data.featuredImage?.node?.srcSet, 'medium') ? getImageBySrcSetAndSize(data.featuredImage?.node?.srcSet, 'medium') : optimizedPath(data.featuredImage?.node?.sourceUrl),
    srcSet: getRangedSrcSet(data.featuredImage?.node?.srcSet, 'medium'),
    alt: (SiteLanguage() !== "en" && data.featuredImage?.node?.atttachement_pl?.altTextPl) ? data.featuredImage?.node?.atttachement_pl?.altTextPl : data.featuredImage?.node.aboutGalleryImage?.altText,
  }

  const author = {
    image: {
      url: getImageBySrcSetAndSize(authorBase?.acfTeamMember.singleUserImage?.srcSet, 'medium') ? getImageBySrcSetAndSize(authorBase?.acfTeamMember.singleUserImage?.srcSet, 'medium') : optimizedPath(authorBase?.acfTeamMember.singleUserImage?.sourceUrl),
      srcSet: getRangedSrcSet(authorBase?.acfTeamMember.singleUserImage?.srcSet, 'medium'),
      alt: (SiteLanguage() !== "en" && authorBase?.acfTeamMember.singleUserImage?.atttachement_pl?.altTextPl) ? authorBase?.acfTeamMember.singleUserImage?.atttachement_pl?.altTextPl : authorBase?.acfTeamMember.singleUserImage?.altText,
    },
    name: authorBase?.title,
  }

  return (
    <PostContent
      link={url}
      title={title}
      excerpt={excerpt}
      buttonLabel={ SiteLanguage() === "en" ? "Read more" : "Czytaj" }
      containerStyle={{ "--image-height": "70%" }}
    >
      {image && (
        <PostImageLink
          to={url}
          anchor={isLinkAnAnchor(uri)}
          style={{ "--position": "static" }}
        >
          {image && <Overlay src={image.url} srcSet={image.srcSet} alt={image.alt} style={{ position: "" }} />}
          <PostMeta>
            <Categories {...{ categories }} />
            <Author>
              <CustomAvatar image={author.image.url} srcSet={author.image.srcSet} alt={author.image.alt} />
              {author.name}
            </Author>
          </PostMeta>
        </PostImageLink>
      )}
    </PostContent>
  )
}

export default ListedPost

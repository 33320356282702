import React from "react"
import styled, { css } from "styled-components"

import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { maxD } from "../../../assets/styles/helpers/devices"

import SiteLanguage from "../../../helpers/siteLanguage"

SwiperCore.use([Autoplay])

export const ClientLogo = styled.img`
  width: min(40%, 160px);
  max-height: 34px;

  object-position: left;
  object-fit: contain;
`

const innerSwiperStyles = css`
  .swiper-wrapper,
  .swiper-slide {
    align-items: center;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;

    height: 100%;
  }
`

const LogosContainer = styled(Swiper)`
  ${innerSwiperStyles}

  display: flex;
  align-items: center;
  margin: 70px 0 40px;

  overflow: hidden;

  @media ${maxD.tabletL} {
    margin: 70px 0 80px;
  }

  @media ${maxD.mobileL} {
    margin: 45px 0 40px;
  }
`

const Logo = styled.img`
  max-width: 75%;
  max-height: 35px;
`

const SmallLogos = ({ logos }) => {
  if (!logos) return null

  return (
    <>
      <LogosContainer
        slidesPerView={4}
        breakpoints={{
          767: {
            slidesPerView: 6,
          },
          1279: {
            slidesPerView: 7,
          },
        }}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {logos.map(logo => (
          <SwiperSlide key={logo.id}>
            <Logo src={logo.localFile.publicURL} alt={(SiteLanguage() !== "en" && logo.atttachement_pl?.altTextPl) ? logo.atttachement_pl.altTextPl : logo.altText} />
          </SwiperSlide>
        ))}
      </LogosContainer>
    </>
  )
}

export default SmallLogos

import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import Cookies from "js-cookie"
import { Link as LinkTo } from "gatsby"

import Analytics from "./Analytics"

import { Button } from "../global/atoms/Button"
import { LineWrapper } from "./common/containers"
import { Checkbox } from "./common/inputs"

import ButtonGroup from "../global/atoms/ButtonGroup"

import { minD, maxD } from "../../assets/styles/helpers/devices"
import { PageWithoutScroll } from "../../assets/styles/helpers/modificators"

const grayOverlay = css`
  position: absolute;
  content: "";

  bottom: 0;
  height: 100vh;

  background-color: var(--dark-gray-transparent-med);
  pointer-events: auto;
`

const Component = styled.aside`
  position: fixed;
  width: 100%;

  bottom: 0;
  left: 0;
  z-index: 9999;

  color: #fff;

  &::before {
    ${grayOverlay}

    left: 0;
    z-index: -1;

    width: 100vw;
  }
`

const Bar = styled.section`
  background-color: var(--dark-gray);
`

const SpacedWrapper = styled(LineWrapper)`
  padding: 3rem 25% 3rem 0;

  @media ${maxD.tablet} {
    padding: 3rem 0;
  }
`

const Header = styled.h2`
  padding: 0 0 1.5rem;
`

const StyledLink = styled(LinkTo)`
  color: #fff;
`

const Preferences = styled.aside`
  --width-decimal: 0.5;

  position: absolute;
  width: calc(100vw * var(--width-decimal));
  height: 100vh;

  bottom: 0;
  right: 0;
  z-index: 10000;

  background-color: var(--main-green);

  transition: transform 350ms ease;

  @media ${maxD.laptop} {
    --width-decimal: 0.75;
  }

  @media ${maxD.tablet} {
    --width-decimal: 1;
  }

  &::before {
    ${grayOverlay}

    left: -100%;

    z-index: 10000;

    width: 100%;
  }
`

const PreferencesContainer = styled(LineWrapper)`
  --vertical-space: 5rem;

  width: calc(var(--wrapper-width) * var(--width-decimal));
  height: calc(100vh - var(--vertical-space) * 2);

  margin: var(--vertical-space) auto;

  @media ${maxD.tablet} {
    --vertical-space: 3rem;

    overflow: auto;
  }
`

const PreferencesButtonContainer = styled.footer`
  @media ${minD.tablet} {
    position: absolute;

    left: 0;
    bottom: 0;
  }
`

const Checkboxes = styled.div`
  padding: 2.5rem 0 0;

  @media ${maxD.mobileS} {
    padding: 1.5rem 0 0.5rem;
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;

  padding: 1.2rem 0 2.2rem;

  line-height: 1.4;

  @media ${maxD.mobileS} {
    padding: 0.8rem 0 1.5rem;
  }
`

const CustomCheckbox = styled(Checkbox)`
  margin: 0 1.1rem 0 0;

  border: 1px solid #fff;
`

const CookiesComponent = () => {
  const [cookiesAccepted, setCookiesAcceptance] = useState(true)
  const [marketing, setMarketing] = useState(true)
  const [statistics, setStatistics] = useState(true)

  const [modalVisible, setModalVisibility] = useState(true)
  const [preferencesVisible, setPreferencesVisibility] = useState(false)

  useEffect(() => {
    const wereCookiesAccepted = Cookies.get("cookies") || false

    setCookiesAcceptance(wereCookiesAccepted)
  }, [cookiesAccepted])

  const saveCookies = () => {
    Cookies.set("cookies", true, {expires: 30})
    Cookies.set("marketing", marketing, {expires: 30})
    Cookies.set("statistics", statistics, {expires: 30})

    setModalVisibility(false)

    setTimeout(() => {
      setCookiesAcceptance(true)
    }, 350)
  }

  const saveCookiesAndPreferences = () => {
    setPreferencesVisibility(false)

    setTimeout(() => {
      saveCookies()
    }, 350)
  }

  const language = Cookies.get("language")

  if (cookiesAccepted && (statistics || marketing))
    return <Analytics {...{ statistics, marketing }} />

  if (cookiesAccepted) return null

  return (
    <>
      <PageWithoutScroll />
      <Component>
        <Bar>
          { language === "en" ?
            <SpacedWrapper
              style={{
                "--color": "var(--line-gray)",
                "--does-bold-line-exists": "none",
              }}
            >
              <Header>We value your privacy</Header>
              <p>
                This site uses cookies to offer you a better browsing experience.
                <br />
                By accepting, you consent to the use of cookies and accept{` `}
                <StyledLink href="/privacy-policy/">privacy policy</StyledLink>
                {` `}terms.
              </p>
              <ButtonGroup>
                <Button theme="green" as="button" onClick={() => saveCookies()}>
                  I am OK with it
                </Button>
                <Button
                  theme="green"
                  as="button"
                  onClick={() => setPreferencesVisibility(!preferencesVisible)}
                >
                  Manage Preferences
                </Button>
              </ButtonGroup>
            </SpacedWrapper>
          :
            <SpacedWrapper
              style={{
                "--color": "var(--line-gray)",
                "--does-bold-line-exists": "none",
              }}
            >
              <Header>Cenimy Twoją prywatność</Header>
              <p>
                Ta strona wykorzystuje ciasteczka by zagwarantować Ci lepsze doświadczenia
                <br />
                Klikając "Zgadzam się", wyrażasz zgodę na użycie ciasteczek oraz akceptujesz naszą {` `}
                <StyledLink href="https://bravenew.agency/pl/polityka-prywatnosci">politykę prywatności</StyledLink>
                {` `}.
              </p>
              <ButtonGroup>
                <Button theme="green" as="button" onClick={() => saveCookies()}>
                  Zgadzam się
                </Button>
                <Button
                  theme="green"
                  as="button"
                  onClick={() => setPreferencesVisibility(!preferencesVisible)}
                >
                  Zarządzaj ustawieniami
                </Button>
              </ButtonGroup>
            </SpacedWrapper>
          }
        </Bar>
        <Preferences
          style={
            !preferencesVisible
              ? {
                  transform:
                    "translateX(calc(var(--width-decimal) * 2 * 100vw))",
                }
              : undefined
          }
        >
          { language === "en" ?
            <PreferencesContainer as="div">
              <Header>Your privacy preferences</Header>
              <p>Please review and manage your privacy settings belows</p>
              <Checkboxes>
                <h3>Essential cookies</h3>
                <Label>
                  <CustomCheckbox as="input" type="checkbox" disabled checked />
                  We need to save some technical cookies, for the website to
                  function properly.
                </Label>
                <h3>Marketing cookies</h3>
                <Label>
                  <CustomCheckbox
                    as="input"
                    type="checkbox"
                    checked={marketing}
                    onClick={() => setMarketing(!marketing)}
                  />
                  We use some cookies for marketing purposes.
                </Label>
                <h3>Statistics</h3>
                <Label>
                  <CustomCheckbox
                    as="input"
                    type="checkbox"
                    checked={statistics}
                    onClick={() => setStatistics(!statistics)}
                  />
                  We use some cookies for gaining statistics.
                </Label>
              </Checkboxes>
              <PreferencesButtonContainer>
                <Button
                  theme="white"
                  as="button"
                  onClick={() => {
                    saveCookiesAndPreferences()
                  }}
                >
                  Save Preferences
                </Button>
              </PreferencesButtonContainer>
            </PreferencesContainer>
          :
            <PreferencesContainer as="div">
              <Header>Ustawienia prywatności</Header>
              <p>Poniżej mozesz sprawdzić i zmienić swoje ustawienia prywatności</p>
              <Checkboxes>
                <h3>Niezbędne ciasteczka</h3>
                <Label>
                  <CustomCheckbox as="input" type="checkbox" disabled checked />
                  Potrzebujemy części ciasteczek do poprawnego funkcjonowania witryny.
                </Label>
                <h3>Marketing cookies</h3>
                <Label>
                  <CustomCheckbox
                    as="input"
                    type="checkbox"
                    checked={marketing}
                    onClick={() => setMarketing(!marketing)}
                  />
                  Część ciasteczek odpowiada za marketing.
                </Label>
                <h3>Statistics</h3>
                <Label>
                  <CustomCheckbox
                    as="input"
                    type="checkbox"
                    checked={statistics}
                    onClick={() => setStatistics(!statistics)}
                  />
                  Część ciasteczek wykorzystujemy do zbierania statystyk.
                </Label>
              </Checkboxes>
              <PreferencesButtonContainer>
                <Button
                  theme="white"
                  as="button"
                  onClick={() => {
                    saveCookiesAndPreferences()
                  }}
                >
                  Zapisz ustawienia
                </Button>
              </PreferencesButtonContainer>
            </PreferencesContainer>
          }
        </Preferences>
      </Component>
    </>
  )
}

export default CookiesComponent

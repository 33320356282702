import React from "react"
import styled from "styled-components"

import { minD, betweenD, maxD } from "../../assets/styles/helpers/devices"

import { Box, UnstyledBoxes } from "../case-study/common/boxes"
import { LineWrapper } from "../global/common/containers"
import { CenterTitle, SecondaryTitle } from "../global/common/text"

const TargetWrapper = styled(LineWrapper)`
  padding-bottom: calc(var(--section-spacing) * 2);

  @media ${maxD.tablet} {
    padding-bottom: var(--section-spacing);
  }
`

const TargetBox = styled(Box)`
  padding: 30px;

  &:nth-child(2n) {
    @media ${minD.tablet} {
      position: relative;
      top: var(--section-spacing);

      @media ${betweenD.tabletTabletL} {
        top: calc(var(--section-spacing) * 0.7);
      }
    }

    @media ${minD.laptop} {
      background-color: var(--faded-green);
      border-color: var(--faded-gray);
      border-left: 0;
    }
  }

  @media ${minD.mobileM} {
    padding: 1.5rem;
  }

  @media ${minD.tablet} {
    width: 50%;
  }

  @media ${minD.laptop} {
    width: 25%;
    padding: 3rem;
  }
`

const Target = ({ data }) => {
  const title = data.offerTargetTitle
  const list = data.targetGroups

  return (
    <TargetWrapper>
      <CenterTitle
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ "--padding-bottom": "6rem" }}
      />
      <UnstyledBoxes>
        {list.map(({ title, description }, i) => (
          <TargetBox key={`key-{ ${i} }`}>
            <SecondaryTitle dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </TargetBox>
        ))}
      </UnstyledBoxes>
    </TargetWrapper>
  )
}

export default Target

import styled from "styled-components"

import { minD, betweenD, maxD } from "../../../assets/styles/helpers/devices"

import { UnstyledList } from "../common/essentials"
import { greenBox, Square } from "../common/ornaments"

export const GalleryInner = styled(UnstyledList)`
  @media ${minD.tablet} {
    --gallery-height: 1990px;
  }

  @media ${minD.tabletL} {
    --gallery-height: 1000px;
  }

  @media ${minD.desktop} {
    --gallery-height: 1100px;
  }

  display: flex;
  flex-flow: wrap column;

  height: var(--gallery-height);

  ${greenBox}

  &::after {
    right: calc(25% + var(--wrapper-right-padding) * 0.75 + 20px);

    @media ${minD.tablet} {
      top: calc(var(--gallery-height) + var(--section-spacing) - 35px);
    }

    @media ${maxD.tabletL} {
      right: calc(50% + var(--wrapper-right-padding) * 0.5 + 30px);
    }

    @media ${maxD.tablet} {
      right: calc(100% - 30px);
      bottom: calc(var(--section-spacing) / 2 + 20px);
    }
  }
`

export const GalleryItem = styled.li`
  width: 25%;

  @media ${maxD.tabletL} {
    width: 50%;
  }

  @media ${maxD.tablet} {
    width: 100%;
    margin-bottom: calc(var(--section-spacing) / 2);
  }

  ${GalleryInner}.classic > & {
    @media ${betweenD.tabletTabletL} {
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        top: 120px;
      }
    }

    @media ${minD.tablet} {
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
        top: 120px;
      }
    }

    @media ${minD.tabletL} {
      &:nth-child(6n - 2),
      &:nth-child(6n - 1),
      &:nth-child(6n) {
        top: 120px;
      }
    }
  }

  ${GalleryInner}.with-double-description > & {
    @media ${betweenD.tabletTabletL} {
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        top: 120px;
      }
    }

    @media ${minD.tablet} {
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11) {
        top: 120px;
      }
    }

    @media ${minD.tabletL} {
      &:nth-child(5) {
        top: 120px;
      }
    }
  }
`

export const ImageLink = styled(Square)`
  ${GalleryItem}:hover > &::after {
    background-color: var(--main-green-transparent-med);
  }
`

export const ImageLinkWithOverlay = styled(ImageLink)`
  @media ${minD.tabletL} {
    ${GalleryItem} > &::after {
      background-color: var(--main-green-transparent-med);
    }

    ${GalleryItem}:hover > &::after {
      background-color: var(--main-green-transparent-max);
    }
  }
`

export const LightboxContainer = styled.section`
  opacity: 0;
  transform: translateY(-100vh);
  visibility: hidden;

  transition: visibility 1ms 1100ms, transform 800ms var(--fancy-cubic-bezier),
    opacity 800ms 300ms;

  &.visible {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;

    transition: visibility 1ms, transform 800ms 1ms var(--fancy-cubic-bezier),
      opacity 400ms 1ms;
  }
`

export const GrayBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 100%;

  background-color: var(--dark-gray);
  color: #fff;

  span {
    color: var(--orange);
  }

  @media ${betweenD.tabletLLaptop} {
    padding: 25px;
  }
`
